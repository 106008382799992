import "src/components/organisms/Shopify/CartProduct.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/organisms/Shopify/CartProduct.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE6VUy46bMBTd8xVXqkZqF1Cbd5zN/ElljENcgW0Zz4S2yr9XgNNiyDDNdAfWeVwfziX6huPTpelLBL8CAK16YYWSBAxvqRWv/BgA0F5zZkNDrVAEkiKHrxDn6Bhcg+jGxxO/UqbmhgDWA/SqFTV8Yox5wHgCnpS0YS9+cgL4oIfj7ejCRXO2BDKExrOWW8tN2GvKhGwI4AinM7oVkodnh8Zx/jQedtQ0QoZWaQIYjcCFcbIxxk5qZYKiHK+46Yab/vvQKCrvDp2haWhN63rG/b3C9LLwzzb+8SP+xY79bmb5qhW06lX7YqdWTAxneLLu8SJqex6FZvE/Zu5dVd/HJp2EJcDUKzeeW7HoUFgpa1W3W6Vynu6WX4z04E2RjKYL/GHC16LXLf1BQCrJ/Q8ACZqTcgLpSoDeF7B8sCFtRSMJMC7teK1FFL5G9c4Q2PAOUBRnhncPizNf3NKq5SHjbXvcBIWz1beu/TQfnwOAvZheGQJaiRlwDZ47XgsKPTOcS6Cyhs+dkKHjFXmphy+T8eoHcXfb9nbrjXr7BZ/acV26pXtu3nal83Z9YAbPMPvo9fbvUTpZF2xcbJ0PK0jiAnqjLh6XrrjoAW71H1zmuJt98VC174BvsV9/A+8lzdnfBgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var ammount = '_12fwgs82';
export var ammountTd = '_12fwgs8a';
export var imageTd = '_12fwgs88';
export var nameTd = '_12fwgs89';
export var productImage = '_12fwgs86';
export var productImageBorder = '_12fwgs81';
export var productImageContainer = '_12fwgs80';
export var productName = '_12fwgs84';
export var productTd = '_12fwgs8c';
export var quantity = '_12fwgs85';
export var tax = '_12fwgs83';
export var totalTd = '_12fwgs8b';
export var tr = '_12fwgs87';
export var trashTd = '_12fwgs8d';