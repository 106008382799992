import "src/components/pages/cart/index.css.tsx.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/pages/cart/index.css.tsx.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71WTW/bMAy951cQLQokwBz4Mx/OZdjQnnfdUbFlR6ssGZKyuhvy3wdLsi2lbhGgwOBDAIl8fKQeyazrbiuFDOHvAuCFlOqUQ4FosYzC8AECSMK2Wx0WAA3qAnu/34dtZ85ETVgOIaCz4v1Ji8qSsDrXfhDCRv8cFpfF2gSKdKCKMxVI8gfnEKcGSx+9YFKfVA5ZGPZnFCuFRSBbVGjQeL3bGWtKGA5O1rqnOtEJjlwp3vTHve0YOdaRSyJbil5zIExjHCkvng8+pSi+nVK43qVzlNKe0hg78WOPQS1jxdscoq3BsSV0D0ec1MdhnGHnNtO3Ry5KLKx724HklJRwj+P+c6w32nogHGcm+q3e2+t3jObeMQ3dt9/57CuKjQfFXVASgQtFOMuh4PTcsP5G4U4FiJKa5UBxpQ6TRs2bj9D7eehfZ6lI9RoUnCnMVA5SIaFhNGpAFG5kDgVmCgsHDn2G6YDmP28icONEOOoIV4o1Nn57+G7FVPYKNYS+5vCDty1h8gtIxGQgsSDV4Uahx9GcdKPs4T31Tw3ff5mnzfL/95dDyCGCNZGCUy5yuH9Msq2B9sJtPWVW7/TnTfq+amN/6tSfQx6z+JbEidt/J43bckmMFAWmSJHf+HBTG0wSne2DaSykdtLbrquIGkC8aW8GvZ4fqHiuBT+zMhi4Pz1932/id5KeGfFzz731Jrwu9EDNZCAxrWYa+ZSjSmFhFWGzv7s7eLVDR8npWenaCTsOLbiOpPdhFj6svPljDKYd9OH8TB77zzGwcWZNlEBMVlw0OQiukMLLNCtxvTIXFCn8cxkYPpfF1waXBIEsBMYMECth2RA2rOrtZtd2K53+2t3005oZZo/Z1BDFdmMDXCan2DrNPKBjlViroSn75eX8B3CjehvPgUgtxChhhY601wHqi9YLq73y2FkPdwLr4l7Z7a3dm17ArLwyLd5mG28M1WtdJkaX87PMgSxvKuDJWg3wQ1N5nRaF46kr/bdpuNqfdJsOztMoHXhc/gFXZMiRDQoAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var a = 'gx7srsh';
export var attentionSmall = 'gx7srsg';
export var cartContainer = 'gx7srs0';
export var empltyProductText = 'gx7srsb';
export var empltyProductWrap = 'gx7srsa';
export var h1 = 'gx7srs1';
export var h2 = 'gx7srs2';
export var p = 'gx7srsc';
export var price = 'gx7srse';
export var small = 'gx7srsf';
export var tableContainer = 'gx7srs3';
export var tax = 'gx7srsd';
export var tbody = 'gx7srs5';
export var th = 'gx7srs7';
export var thead = 'gx7srs4';
export var totalPriceContainer = 'gx7srs9';
export var totalWrap = 'gx7srs8';
export var tr = 'gx7srs6';